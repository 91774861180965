/**
 * Created by yura on 15.11.15.
 */
var Main = {
    init:function(){
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $('input[type=text]').on('click',function(){
            var id = this.name;
            var el =  $(this).closest('.input-field').find('#'+id+'-error');
            el.html('');

            $(this).closest('.input-field').removeClass('error');
        });

        $('textarea').on('click',function(){
            var id = this.name;
            var el =  $(this).closest('.input-field').find('#'+id+'-error');
            el.html('');

            $(this).closest('.input-field').removeClass('error');
        });

        $('.reply-link').on('click',function(){
            var text = $(this).closest('article').find('span.name').html();
            $('#parent_id').val($(this).data('id'));
            $('#comment').closest('.input-field').addClass('used');
            $('#comment').val(text+', ');
        });
    },
    formSubmit:function(form){
        var url = form.action;
        $.ajax({
            type: "POST",
            url: url,
            data: $(form).serialize(),
            dataType: "json",
            success: function(data){
                Main.actionData(data,form);
            }
        });

        return false;
    },
    showNotifyMessage: function(title,text,type,simbol,className){
        if(!type)
            type='success';

        new PNotify({
            title: title,
            text: text,
            icon: simbol,
            type: type,
            addclass:className,
            delay:60000,
        });
    },
    showValidationMessage: function(data,form){
        $.each(data,function(i,value){
            var input = $(form).find('#'+i);
            var el = $(form).find('#'+i+'-error');
            input.closest('.input-field').addClass('error');
            el.html(value);
        });
    },
    actionData: function(data,form){
        if(typeof data['redirect'] != 'undefined'){
            if(data['redirect']=='')
                window.location.reload();
            else
                window.location = data['redirect'];
        }else{
            if(typeof data['message'] != 'undefined')
                Main.showNotifyMessage(data['title'],data['message'],data['icon'],data['class']);
            else
                Main.showValidationMessage(data,form);
        }
    },
    getLangLink:  function(uri){
        var lang = $('html').attr('lang');
        if(lang)
            return '/'+lang+uri;
        else
            return uri;
    }

};

$(document).ready(function(){
    Main.init();
});
